import Head from 'next/head';
import NextLink from 'next/link';
import Image from 'next/image';
import { Container, Grid, Typography, Link } from '@mui/material';

import { getMenuEntriesFromContentful } from 'lib/contentful/menuHelper';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';

interface NotFoundPageProps {
  menuEntries: any;
}

const NotFoundPage = (props: NotFoundPageProps) => {
  const { menuEntries } = props;

  const notFoundImageUrl =
    'https://images.ctfassets.net/ymbams5xdqfz/1fea5Et5puAhrVHt5Wy1dh/1b29c808575464f2f07e0342fc7b2074/kai-not-found.jpg';
  const width = 300;
  const height = width * 1.5;

  return (
    <>
      <Head>
        <title>Error 404</title>
      </Head>

      <Header menuEntries={menuEntries} />

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Typography variant="h1" sx={{ mb: 4 }}>
          Error 404 - Wo ist die Seite hin?
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ textAlign: 'center' }}>
            <Image
              src={notFoundImageUrl}
              width={width}
              height={height}
              alt="Kai schaut durch eine Sonnenblende"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ mb: 2, mt: 2 }}>
              Irgendwie sollte hier eine Seite sein... Sorry!
            </Typography>
            <Typography variant="body1">
              Zurück zur{' '}
              <Link href="/" component={NextLink}>
                Startseite
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
};

export const getStaticProps = async () => {
  const menuEntries = await getMenuEntriesFromContentful('Main-Menu');

  return { props: { menuEntries } };
};

export default NotFoundPage;
